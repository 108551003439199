<template>
  <el-main class="rh-medform">
    <form-tutoratcreate :routes="tutRoutes" :ismedtut="true" />
  </el-main>
</template>
<script>
import FormTutoratcreate from "@/components/rh/formulaires/FormTutoratcreate";

export default {
  components: { "form-tutoratcreate": FormTutoratcreate },
  computed: {
    tutRoutes() {
      return {
        toedit: {
          name: "med_tuteurs_edit",
          params: {
            idmed: this.$route.params.idmed,
            idtutorat: this.$store.state.tut.tuteurLastCreatedId,
          },
        },
        tolist: {
          name: "med_tuteurs",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
