<template>
  <div class="app-form">
    <el-form
      ref="formTutorat"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row>
        <el-col :span="24">
          <!-- Tuteur -->
          <el-form-item label="Tuteur" prop="id_tuteur" v-show="!ismedpup">
            <el-select
              v-model="form.id_tuteur"
              clearable
              filterable
              placeholder="Choisissez un tuteur"
            >
              <el-option
                v-for="item in tuteurOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Pupille -->
          <el-form-item label="Pupille" prop="id_pupille" v-show="!ismedtut">
            <el-select
              v-model="form.id_pupille"
              clearable
              filterable
              placeholder="Choisissez un pupille"
            >
              <el-option
                v-for="item in pupilleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- Date début -->
          <el-form-item label="Début" prop="debut_date">
            <el-date-picker
              v-model="form.debut_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <!-- Date fin -->
          <el-form-item label="Fin" prop="fin_date">
            <el-date-picker
              v-model="form.fin_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <!-- Trimestre prochaine évaluation -->
          <el-form-item
            label="Trimestre prochaine évaluation"
            prop="prochain_eval_date"
          >
            <el-select
              v-model="form.prochain_eval_date"
              clearable
              filterable
              placeholder="Choisissez un trimestre"
            >
              <el-option
                v-for="item in trimestreOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var valideDebut = (rule, value, callback) => {
      if (value) {
        if (this.isEvalDates) {
          if (value > this.evaldates.min) {
            callback(
              new Error(
                "La date ne peut pas être postérieure à la date de la 1ère évaluation !"
              )
            );
          } else {
            callback();
            this.$refs.formTutorat.validateField("fin_date");
            this.$refs.formTutorat.validateField("prochain_eval_date");
          }
        } else {
          callback();
          this.$refs.formTutorat.validateField("fin_date");
          this.$refs.formTutorat.validateField("prochain_eval_date");
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    var valideFin = (rule, value, callback) => {
      if (value) {
        if (this.form.debut_date && this.form.debut_date >= value) {
          callback(
            new Error(
              "La date de fin doit être postérieure à la date de début !"
            )
          );
        } else if (this.isEvalDates) {
          if (value < this.evaldates.max) {
            callback(
              new Error(
                "La date ne peut pas être antérieure à la date de la dernière évaluation !"
              )
            );
          } else {
            callback();
            this.$refs.formTutorat.validateField("prochain_eval_date");
          }
        } else {
          callback();
          this.$refs.formTutorat.validateField("prochain_eval_date");
        }
      } else {
        callback();
      }
    };
    var valideProchainEval = (rule, value, callback) => {
      if (value) {
        const moisDebutFinTrim = {
          "01": ["01", "03"],
          "02": ["04", "06"],
          "03": ["07", "09"],
          "04": ["10", "12"],
        };
        const an = value.substring(0, 4);
        const trim = value.substring(5, 7);
        const trimMoisDebut = an + "-" + moisDebutFinTrim[trim][0];
        const trimMoisFin = an + "-" + moisDebutFinTrim[trim][1];
        if (
          this.form.debut_date
            ? this.form.debut_date.substring(0, 7) > trimMoisFin
            : false
        ) {
          callback(
            new Error(
              "Le trimestre de la prochaine évaluation ne doit pas être antérieur à la date de début !"
            )
          );
        } else if (
          this.form.fin_date
            ? this.form.fin_date.substring(0, 7) < trimMoisDebut
            : false
        ) {
          callback(
            new Error(
              "Le trimestre de la prochaine évaluation ne doit pas être postérieur à la date de fin !"
            )
          );
        } else if (
          this.isEvalDates
            ? this.evaldates.max.substring(0, 7) >= trimMoisDebut
            : false
        ) {
          callback(
            new Error(
              "Le trimestre de la prochaine évaluation doit être postérieur au mois de la dernière évaluation !"
            )
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      rules: {
        id_tuteur: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_pupille: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        debut_date: [
          { required: true, validator: valideDebut, trigger: "change" },
        ],
        fin_date: [
          { required: false, validator: valideFin, trigger: "change" },
        ],
        prochain_eval_date: [
          { required: false, validator: valideProchainEval, trigger: "change" },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "+ 6 mois",
            onClick(picker) {
              const dt = new Date();
              dt.setMonth(dt.getMonth() + 6);
              picker.$emit("pick", dt);
            },
          },
          {
            text: "+ 1 an",
            onClick(picker) {
              const dt = new Date();
              dt.setMonth(dt.getMonth() + 12);
              picker.$emit("pick", dt);
            },
          },
        ],
      },
    };
  },
  props: {
    ismedtut: Boolean,
    ismedpup: Boolean,
    evaldates: Object,
    inittrim: String,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.tut.tuteur;
      },
      set(form) {
        this.$store.commit("tut/setTuteur", form);
      },
    },
    tuteurOptions() {
      // que les médecins cadres MC
      const tutselect = this.$store.getters["medl/medecinSelect"].filter(
        (m) => m.lastposte == "MC"
      );
      const pup = this.form.id_pupille;
      return pup ? tutselect.filter((m) => m.value != pup) : tutselect;
    },
    pupilleOptions() {
      const medselect = this.$store.getters["medl/medecinSelect"];
      const tut = this.form.id_tuteur;
      return tut ? medselect.filter((m) => m.value != tut) : medselect;
    },
    trimestreOptions() {
      const trimActu = this.inittrim;
      const td = new Date(); // date du jour
      let tdAn = td.getFullYear();
      let tdTrim = Math.ceil((td.getMonth() + 1) / 3);
      let opts = [];

      for (let i = 0; i < 12; i++) {
        if (tdTrim == 5) {
          tdTrim = 1;
          tdAn++;
        }
        opts.push(tdAn.toString() + "-0" + tdTrim.toString());
        tdTrim++;
      }

      if (trimActu < opts[0]) {
        // ajoute l'option existante au début
        opts.unshift(trimActu);
      } else if (trimActu > opts[opts.length - 1]) {
        // ajoute l'optio   n à la fin
        opts.push(trimActu);
      }

      return opts;
    },
    isEvalDates() {
      // check si existe des dates d'évaluation pour le tutorat
      return this.evaldates ? !Object.keys(this.evaldates).length === 0 : false;
    },
  },

  methods: {},
};
</script>
