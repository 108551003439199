<template>
  <div>
    <form-tutorat :ismedtut="ismedtut" :ismedpup="ismedpup" ref="formTut">
    </form-tutorat>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormTutorat from "@/components/rh/ui/form/FormTutorat";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-tutorat": FormTutorat },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismedtut: Boolean,
    ismedpup: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    let medParam = { tut: null, pup: null };
    if (this.ismedtut) {
      // id du médecin = id Pupille si dans détail médecin > tuteurs
      medParam.pup = idMed;
    } else if (this.ismedpup) {
      // id du médecin = id Tuteur si dans détail médecin > pupilles
      medParam.tut = idMed;
    }
    this.$store.commit("tut/resetTuteur", medParam);
  },
  computed: {
    tutoratCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.tut.tuteurCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formTut.$refs.formTutorat.validate((valid) => {
        if (valid) {
          let tut = this.$store.state.tut.tuteur;

          // enlever les espaces avant et après dans les string
          trimStringInObj(tut);

          // vérifie si un tutorat entre les mêmes méd et avec la même date de debut n'est pas déjà enregistré
          const tutsInBD = this.$store.getters["tut/TutsInBD"];
          const doublon =
            tutsInBD.filter(
              (m) =>
                m[1] == tut.id_pupille &&
                m[2] == tut.id_tuteur &&
                m[3] == tut.debut_date
            ).length > 0;
          if (doublon) {
            this.$alert(
              "Un tutorat entre ces 2 personnes avec la même date de début est déjà enregistré !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("tut/createTuteur", tut);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des formations continues
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    tutoratCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.tutoratCreateStatus == 2) {
        // message succès
        this.$message({
          message: "Le tutorat a été ajouté à la base de données",
          type: "success",
        });

        // va au formulaire d'édition du contrat
        this.$router.push(this.routes.toedit);
      } else if (this.tutoratCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.tutoratCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.tutoratCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
